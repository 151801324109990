<template>
  <div class="vue-video">
    <div class="vue-video-box">
      <div
        v-if="hasHiddenCard && deviceWidth > 960"
        class="vue-video__arrow vue-video__prev"
        :class="{ disabled: currentSlide === 0 }"
        @click="prev"
      >
        <ArrowLeft />
      </div>

      <Carousel
        ref="carouselRef"
        v-model="currentSlide"
        :breakpoints="breakpoints"
        @slideEnd="slideEnd"
      >
        <Slide
          v-for="(card, index) in list"
          :key="index"
          class="slideEl"
        >
          <VideoCard
            v-if="isVideo"
            :card="card as MediaFile"
            class="carousel__slide"
          />
          <RecentCard
            v-else
            :card="card as LabData"
            class="carousel__slide"
          />
        </Slide>
      </Carousel>

      <div
        v-if="hasHiddenCard && deviceWidth > 960"
        class="vue-video__arrow vue-video__next"
        :class="{ disabled: isRightArrowDisabled }"
        @click="next"
      >
        <ArrowLeft />
      </div>
    </div>

    <div
      v-if="hasHiddenCard"
      class="pagination"
    >
      <div
        v-for="(isVisible, index) in visibleCards"
        :key="`pag-${index}`"
        class="pagination-item"
        :class="{ disabled: isVisible }"
        @click="goTo(index, isVisible)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, nextTick, onUnmounted, computed } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import { LabData } from '@/types/LabData';
import ArrowLeft from '@/components/icons/CarouselLeft.vue';
import RecentCard from '@/components/Carousel/RecentCard.vue';
import VideoCard from '@/components/Carousel/VideoCard.vue';
import { MediaFile } from '@/types/manuals';
import { debounce } from '@/utils';

const props = defineProps<{
  list: LabData[] | MediaFile[];
  isVideo?: boolean;
}>();

const deviceWidth = ref<number>();
const currentSlide = ref(0);
const carouselRef = ref();
const visibleCards = ref<boolean[]>([]);
const breakpoints = ref();

const hasHiddenCard = ref(false);
const checkHiddenCards = debounce(() => {
  if (!carouselRef.value) { return false; }
  const scrollBox = carouselRef.value.$el;
  const slides = scrollBox.querySelectorAll('.slideEl');
  slides.forEach((el: Element) => {
    hasHiddenCard.value = el.getAttribute('aria-hidden') === 'true';
  });
}, 100);

const updVisibleCards = debounce(() => {
  const scrollBox = carouselRef.value.$el;
  const items: HTMLElement[] = scrollBox.querySelectorAll('.slideEl');
  nextTick(() => {
    visibleCards.value = [];
    Array.from(items).forEach((item) => {
      visibleCards.value.push(item.getAttribute('aria-hidden') === 'false');
    });
  });
}, 100);

const resizeAction = () => {
  updVisibleCards();
  checkHiddenCards();

  deviceWidth.value = window.innerWidth;
};

onMounted(() => {
  deviceWidth.value = window.innerWidth;

  breakpoints.value = {
    // 320 and up
    320: {
      itemsToShow: carouselRef.value.data.maxSlide.value > 0 ? 1.25 : 1,
      snapAlign: 'center',
    },
    480: {
      itemsToShow: carouselRef.value.data.maxSlide.value > 0 ? 2.25 : 2,
      snapAlign: 'center',
    },
    961: {
      itemsToShow: 3,
      snapAlign: 'start',
    },
  };

  updVisibleCards();
  checkHiddenCards();
  window.addEventListener('resize', resizeAction);
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeAction);
});

const goTo = (index: number, isVisible: boolean) => {
  if (isVisible) { return; }
  carouselRef.value.slideTo(index);
};

const slideEnd = () => {
  updVisibleCards();
};

const prev = () => {
  const index = visibleCards.value.findIndex((x: boolean) => x);
  carouselRef.value.slideTo(index - 1);
};
const next = () => {
  const index = visibleCards.value.findLastIndex((x: boolean) => x);
  carouselRef.value.slideTo(index - 1);
};

const isRightArrowDisabled = computed(() => {
  const visibleCount = visibleCards.value.filter((i) => i).length;
  return currentSlide.value === props.list.length - visibleCount;
});
</script>

<style lang="scss">
@import "@/assets/style/include";
.vue-video {

  .is-dragging {
    pointer-events: none;
  }

  &-box {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 8px;

    &-item {
      padding: 6px;
      cursor: pointer;

      &:before {
        width: 10px;
        height: 10px;
        content: '';
        display: block;
        border: 1px solid $colorBlue1;
        transform: rotate(45deg);
        transition: background .2s;
      }

      &.disabled {
        cursor: auto;
        &:before {
          background: $colorBlueHover;
          border-color: $colorBlueHover;
        }
      }

      &:hover {
        &:before {
          background: $colorBlueHover;
          border-color: $colorBlueHover;
        }
      }

      &:active {
        &:before {
          background: $colorBluePressed;
          border-color: $colorBluePressed;
        }
      }
    }
  }

  &__arrow {
    height: 100px;
    color: $colorBlue1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      cursor: not-allowed;
      color: $colorGrayDisabled;

      &:hover, &:active {
        color: $colorGrayDisabled;
      }
    }

    & svg {
      width: 44px;
    }

    &:hover {
      color: $colorBlueHover;
    }
    &:active {
      color: $colorBluePressed;
    }
  }
  &__next {
    transform: rotate(180deg);

  }

  .carousel__pagination-button {
    padding: 10px;
  }
}

.carousel__slide:has(.recent-card) {
  min-height: 112px;
}

@include media-breakpoint-down("tablet") {
  .vue-video {

    .carousel {
      width: 100%;
    }

    .pagination {
      display: none;
    }
  }
}
@include media-breakpoint-down("mobile") {
  .vue-video {

    .carousel {
      width: 100%;
    }

    &__arrow {
      display: none;
    }
  }
}
</style>
