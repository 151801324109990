<template>
  <component
    :is="linkMore ? 'router-link' : 'div'"
    :to="linkMore"
    :class="['card', { hold: isAnnouncement }]"
  >
    <div class="card__subject">
      <div>{{ card.subsection.name }}</div>
      <template v-if="props.card.license_state && licenseStateLabel">
        <div>
          <ElTooltip
            class="card__tooltip-time"
            effect="light"
            :content="expirationDateText"
            :disabled="!expirationDateText"
            rawContent
            placement="top"
          >
            <span
              class="card__subscribe"
              :class="{
                expire: isSoonExpire && !isOrganisation,
              }"
            >
              {{ licenseStateLabel }}
            </span>
          </ElTooltip>
        </div>
      </template>
    </div>

    <div class="card__image-box">
      <picture :class="opacityClass">
        <SourceImage :card="card" />
        <img
          src="/img/empty/card.png"
          :alt="card.name"
          class="card__img"
          loading="lazy"
        />
      </picture>
      <div class="card__front">
        <template v-if="isSuAccessRole">
          <ElTooltip
            v-if="tooltipIconText"
            effect="light"
            :content="tooltipIconText.text"
            rawContent
            placement="bottom"
          >
            <button
              class="card__btn-public"
              type="button"
            >
              <Component :is="tooltipIconText.icon" />
            </button>
          </ElTooltip>
        </template>

        <div
          v-if="card.has_workbook"
          class="card__workbook"
        >
          <IconWorkbook />
        </div>

        <div
          v-if="isAnnouncement"
          class="card__hold"
        >
          {{ t("labs.soon") }}
        </div>
        <LaunchProduct
          v-else
          :card="card"
          class="card__play"
        />
        <div class="card__buttons">
          <router-link
            v-if="isSuAccessRole"
            class="card__btn"
            :to="linkEditPage"
          >
            {{ t('universalEdit') }}
          </router-link>

          <router-link
            v-if="!isAnnouncement"
            class="card__btn"
            :to="linkMore"
          >
            {{ t('more') }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="card__title">
      {{ card.name }}
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ElTooltip } from 'element-plus';
import { LocationAsRelativeRaw } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { names } from '@/constants/routes';
import IconWorkbook from '@/components/icons/NoteBook.vue';
import IconPencilLine from '@/components/icons/PencilLine.vue';
import IconPublishing from '@/components/icons/Publishing.vue';
import IconEyeSlash from '@/components/icons/EyeSlash.vue';
import { LabData } from '@/types/LabData';
import { ProductStateEnum } from '@/types/enums';
import dayjs from '@/utils/dayjs';
import LaunchProduct from '@/components/Card/LaunchProduct.vue';
import SourceImage from '@/components/Card/SourceImage.vue';
import { LicenseState } from '@/router/Laboratories/Laboratory/constants';
import { formatDate } from '@/utils/utils-format';

const props = defineProps<{
  card: LabData;
}>();

const { t } = useI18n();
const store = useStore();

const isPublication = computed(() => props.card.state === ProductStateEnum.Publication
  || props.card.state === ProductStateEnum.RePublication);
const isDraft = computed(() => props.card.state === ProductStateEnum.Draft);
const isNotPublished = computed(() => !isDraft.value && !props.card.enabled);
const isAnnouncement = computed(() => props.card.enabled_as_promo);

const isSuAccessRole = computed(() => store.getters.isSuAccessRole);

const opacityClass = computed(() => ({
  'is-draft': isDraft.value && !isAnnouncement.value,
  'not-published': isPublication.value || isNotPublished.value,
}));

const expirationDate = computed(() => {
  if (!props.card.license_expiration_date) {
    return '';
  }

  return new Date(props.card.license_expiration_date).getTime();
});
const isSoonExpire = expirationDate.value
    && ((expirationDate.value - new Date().getTime()) / 1000 / 60 / 60) < 48;

const expirationDateFormat = 'DD MMMM YYYY';
const expirationTimeFormat = 'HH:mm A';

const expirationDateText = computed(() => {
  let date = '';

  if (isOrganisation.value || !expirationDate.value || !props.card.active_for_user) {
    return date;
  }

  if (isSoonExpire) {
    date = `${t('expires')} ${formatDate(expirationDate.value, expirationDateFormat)} ${t('prepositions.at')} ${dayjs(expirationDate.value).format(expirationTimeFormat)}`;
  } else {
    date = `${t('expires')} ${formatDate(expirationDate.value, expirationDateFormat)}`;
  }

  return date;
});

const tooltipIconText = computed(() => {
  if (isPublication.value) {
    return {
      text: t('publication'),
      icon: IconPublishing,
    };
  }
  if (isNotPublished.value) {
    return {
      text: t('notPublished'),
      icon: IconEyeSlash,
    };
  }
  if (isDraft.value && !isAnnouncement.value) {
    return {
      text: t('draft'),
      icon: IconPencilLine,
    };
  }
  return false;
});

const linkMore = computed<LocationAsRelativeRaw>(() => {
  if (!isAnnouncement.value) {
    return { name: names.laboratory, params: { id: props.card.id } };
  }
  return undefined;
});
const linkEditPage = computed<LocationAsRelativeRaw>(() => (
  { name: names.editProduct, params: { id: props.card.id } }
));

const licenseType = computed(() => {
  if (typeof props.card.license_state === 'boolean') {
    return false;
  }
  return props.card.license_state.replace('licenses.license_state_', '');
});

const isOrganisation = computed(() => props.card.license_state === LicenseState.Organization);

const licenseStateLabel = computed(() => {
  if (!licenseType.value) {
    return false;
  }
  return t(`labs.licenseState.${licenseType.value}`);
});
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.card {
  border-radius: 14px;
  display: block;
  position: relative;
  width: 100%;
  background: white;
  font-size: 16px;
  border: 2px solid $bgLight1;
  transition: border $transition-speed;

  &:deep(.card__tooltip-time):not([data-type="tooltip"]) {
    left: auto;
    right: 0;
    transform: translateX(0px);
    bottom: 106%;
    box-shadow: none;

    &:before,
    &:after {
      left: 88%;
    }
  }

  picture {
    overflow: hidden;
  }

  &__img {
    width: 100%;
    transition: .2s filter;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    transform: scale(1.05);
  }

  $playTranslate: translate(-50%, calc(-50% - 15px));
  &:deep(.card__play) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: $playTranslate scale(.5);
    transition: $transition-speed;
    opacity: 0;
    z-index: 2;
    height: 40%;
    color: white;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    border: 2px solid $colorBlue1;

    .card__buttons {
      opacity: 1;
    }

    :deep(.card__play) {
      transform: $playTranslate scale(1);
      opacity: 1;
    }
  }

  &:not(.hold) {
    &:hover {
      .card__img {
        filter: blur(6px);
      }
    }
  }

  &__image-box {
    position: relative;
  }

  &__buttons {
    width: calc(100% - 40px);
    position: absolute;
    bottom: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;
    opacity: 0;
    transition: $transition-speed opacity;
  }
  &__btn {
    flex: 1;
    height: 27px;
    line-height: 27px;
    background: white;
    text-align: center;
    border-radius: 3px;
    transition: $transition-speed;
    font-family: PT Sans, sans-serif;

    &:hover {
      filter: contrast(0.8);
    }
  }

  &:is(a) {
    cursor: pointer;
    transition: border-color $transition-speed;
  }

  &__btn-public,
  &__workbook {
    @include flex-center(inline-flex);
  }

  &__btn-public {
    width: 36px;
    height: 26px;
    background-color: $colorBlue1;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  &__front {
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    padding: 18px;
    position: absolute;
    top: 0;
    width: 100%;

    & [data-type="tooltip"] {
      width: fit-content;
    }
  }

  &__hold {
    background: white;
    font-size: 1.8rem;
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 1rem 4rem;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    mix-blend-mode: screen;
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }

  .not-published {
    opacity: .5;
  }
  .is-draft {
    opacity: .3;
  }
  .anons-not-published {
    opacity: .2;
  }
}

.card__subject {
  height: 44px;
  line-height: 44px;
  color: $colorBlue1;
  font-weight: 700;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}

.card__subscribe {
  color: $colorBlue1;
  border-radius: 4px;
  border: 1px solid currentColor;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  text-transform: none;
  white-space: nowrap;

  &.expire:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    position: absolute;
    top: -5px;
    right: -5px;
  }
}

.card__title {
  padding: 12px 20px;
  color: $colorText1;
}

.card__workbook {
  width: 36px;
  height: 26px;
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 6px;
  border-radius: 3px;
  background: $color-peach;
  font-size: 34px;

  svg {
    width: 15px;
    height: 16px;
  }
}

@include media-breakpoint-down("tablet") {
  .card {
    &__title {
      font-size: 20px;
    }
    &:deep(.card__play) {
      display: none;
    }
  }
}

</style>
