<template>
  <footer class="footer">
    <div
      class="footer__content"
      :class="contentClass"
    >
      <div class="footer__doc">
        <a
          v-if="showPdfLink"
          :href="documents.publicOffer"
          target="_blank"
          class="footer__link"
        >
          {{ t("footer.publicOffer") }}
        </a>
        <a
          v-if="showPdfLink"
          :href="documents.termsOfUse"
          target="_blank"
          class="footer__link"
        >
          {{ t("footer.termsOfUse") }}
        </a>

        <a
          v-if="currentSupportLink"
          target="_blank"
          class="footer__link"
          :href="currentSupportLink"
        >
          {{ t("footer.support") }}
        </a>
        <button
          type="button"
          class="footer__link"
          @click="handleModalToggle(true)"
        >
          {{ t("footer.systemRequirements") }}
        </button>
        <a
          v-if="showPdfLink"
          :href="documents.personalDataProcessingRules"
          target="_blank"
          class="footer__link"
        >
          {{ t("footer.personalDataProcessingRules") }}
        </a>
      </div>

      <div class="footer__vizex">
        <div
          class="footer__copyright"
          v-html="currentCopyright(t, date)"
        />
      </div>

      <SystemRequirementsModal
        :isOpen="isModalOpen"
        @close="handleModalToggle($event)"
      />
    </div>
  </footer>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import useDocumentLinks from '@/composition/useDocumentLinks';
import dayjs from '@/utils/dayjs';
import { currentCopyright, currentSupportLink } from '@/utils';
import SystemRequirementsModal from '@/components/SystemRequirmentsModal.vue';
import { names } from '@/constants/routes';

const { t } = useI18n();
const route = useRoute();

const contentClass = computed(
  () => (route.name === names.laboratory ? 'container' : 'container-flex')
);

const isModalOpen = ref(false);
const date = dayjs().format('YYYY');
const documents = useDocumentLinks();

// TODO: show en and fr document links
// Ссылки в футере временно скрыты для международного портала
const showPdfLink = process.env.VUE_APP_TYPE !== 'international';

const handleModalToggle = (flag: boolean) => {
  isModalOpen.value = flag;
};
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.footer {
  background: $color-white;
  border-top: 1px solid $colorBlue1;
  font-size: 14px;

  &__content {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  &__doc,
  button.footer__link,
  &__copyright {
    color: $colorText1;
  }

  &__copyright {
    font-size: 10px;
    white-space: nowrap;
  }

  &__doc {
    display: grid;
    gap: 4px 20px;
    grid-template-columns: 9fr 9fr 6fr;
  }

  &__link {
    cursor: pointer;
    font-weight: 300;
    text-align: left;
    font-size: 14px;
  }

  &__link,
  button.footer__link {
    transition: .2s linear;

    &:hover {
      color: $colorBlueHover;
    }

    &.disabled {
      pointer-events: none;
      cursor: pointer;
    }
  }

  &__vizex {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
}

@include media-breakpoint-down("tablet") {
  .footer {
    &__doc {
      grid-template-columns: 1fr 1fr;
    }
    &__link {
      font-size: 12px;
    }
  }
}

@include media-breakpoint-down("mobile") {
  .footer {

    &__content {
      flex-direction: column;
      align-items: center;
    }

    &__doc {
      gap: 4px;
      grid-template-columns: 1fr;
      margin-bottom: 28px;
    }

    &__link {
      text-align: center;
      font-weight: 400;
    }

    &__vizex {
      align-items: center;
    }
  }
}
</style>
