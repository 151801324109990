<template>
  <source
    v-for="(srcset, media) in mediaSrcSets"
    :key="media"
    :media="media"
    :srcset="srcset"
  />
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import { imageCandidatesToSrcset } from '@/utils';
import { LabData } from '@/types/LabData';

const props = defineProps<{
  card: LabData;
}>();

const appImg = toRef(() => props.card.app_img);

const mediaSrcSets = computed(() => ({
  '(max-width: 575px)': imageCandidatesToSrcset({
    '2x': appImg.value['800x560'],
  }),
  '(min-width: 576px)': imageCandidatesToSrcset({
    '1x': appImg.value['400x280'],
  }),
}));

</script>
