<template>
  <div class="filter">
    <SearchSwitch
      :value="filterWord || ''"
      :isNotFound="isNotFound"
      :placeholder="t('search')"
      useInputEvent
      @input="handleSearchInput"
    />

    <div class="filter__buttons">
      <button
        v-if="countLicense"
        class="toggle__btn filter__active"
        :class="{ checked: hasLicense }"
        type="button"
        @click="$emit('update:license', !hasLicense);"
      >
        {{ t('activeLicense') }}
        <span>
          ({{ countLicense }})
        </span>
      </button>

      <button
        v-for="(button, index) in filterSections.values()"
        :key="`btn-${index}`"
        :class="['toggle__btn', { active: button?.active }]"
        type="button"
        @click="addActive(button.shortname)"
      >
        {{ button?.name }}
        <span>
          ({{ button.count }})
        </span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import SearchSwitch from '@/components/inputs/SearchSwitch.vue';
import { FilterButton } from '@/types/LabData';

defineProps<{
  filterSections: Map<string, FilterButton>;
  countLicense: number;
  hasLicense: boolean;
  filterWord?: string;
  isNotFound?: boolean;
}>();

const emit = defineEmits<{
  (ev: 'update:group', key: string): void;
  (ev: 'update:search', type: string): void;
  (ev: 'update:license', val: boolean): void;
}>();

const { t } = useI18n();

// METHODS
const addActive = (key: string) => {
  emit('update:group', key);
};

const handleSearchInput = (val: string) => {
  emit('update:search', val);
};
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.filter {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  position: relative;
  margin: 16px 0;

  &__active {
    color: $color-peach;
    border-color: $color-peach;

    &:hover {
      background: #FFE3DC;
    }
    &:active, &.checked {
      color: white;
      background: #FD8263;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
  }
}
</style>
