import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';

const notFound: Array<RouteRecordRaw> = [
  {
    name: names.alerts.notFound,
    path: paths.alerts.notFound,
    meta: {
      permissions: permissions.alerts,
      isAuthed: false,
      requiresAuth: false,
    },
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: 'administration-users' */
      './index.vue'
    ),
  },
];

export default notFound;
