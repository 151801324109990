<template>
  <div
    :class="{ 'freeze': uploader.fileUploading, disabled }"
    class="file-upload"
  >
    <label
      class="file-upload__label"
      :class="{
        'file-upload__selected': fileName || hasError,
        'file-upload__selected_has-error': hasError,
      }"
    >
      <input
        ref="fileInputEl"
        type="file"
        :accept="accept"
        @change="handleFileChange"
      />
      <span
        class="file-upload__name"
        :class="{ 'file-upload__name_has-error': hasError }"
      >
        {{ inputLabel }}
      </span>
    </label>

    <InputAction
      v-if="copy && !hasError"
      :disabled="isCopyDisabled"
      @click="copyToClipboard"
    >
      <IconCopy />
    </InputAction>
    <InputAction
      v-else-if="hasError"
      :disabled="disabled"
      @click="handleUploadClick"
    >
      <UploadIcon />
    </InputAction>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, reactive } from 'vue';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import InputAction from '@/components/Form/InputAction.vue';
import { Upload, UploadedPayloadType } from '@/utils/fileUploader';
import IconCopy from '@/components/icons/Copy.vue';
import UploadIcon from '@/components/icons/Upload.vue';

const { t } = useI18n();

const emits = defineEmits<{
  (e: 'fileLoading', bool: boolean): void;
  (e: 'fileUpdate', data: UploadedPayloadType): void;
}>();

interface Props {
  accept?: string;
  copy?: boolean;
  url?: string;
  error?: boolean;
  disabledCopy?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  accept: '',
  copy: false,
  url: '',
  error: false,
  disabledCopy: false,
});
const fileInputEl = ref<HTMLInputElement | null>(null);

const file = ref<File | null>(null);
const hasError = computed(() => props.error);
const fileName = computed(() => props?.url || file.value?.name);
const uploader = reactive(new Upload());
const isCopyDisabled = computed(() => uploader.fileUploading || props.disabledCopy);
const progress = computed(
  () => (!uploader.fileUploading && fileName.value ? '100%' : `${Math.ceil(uploader.progress / 1.1)}%`)
);
const inputLabel = computed(() => {
  if (uploader.fileUploading) {
    return t('alerts.fileLoading');
  }
  return hasError.value ? t('alerts.fileNotFound') : fileName.value;
});

const handleUploadClick = () => {
  fileInputEl.value?.click();
};

const getFileExtension = (
  mimeType: string
): string => mimeType.split('/').pop() || mimeType;

const handleFileChange = async (event: Event) => {
  const { files } = event.target as HTMLInputElement;

  if ((!files || !props.accept.includes(
    getFileExtension(files[0].type)
  )) && props.accept) {
    ElNotification({
      message: t('notifications.fileExtension', { extension: props.accept }),
      type: 'warning',
      position: 'bottom-right',
      dangerouslyUseHTMLString: true,
    });
    return;
  }
  const inputElement = fileInputEl.value;
  if (!inputElement?.files?.[0]) {
    emits('fileLoading', false);
    return false;
  }
  const [selectedFile] = inputElement.files;
  file.value = selectedFile;
  emits('fileLoading', true);
  const response: UploadedPayloadType = await uploader.downloadFile(file.value);
  if (!response?.name || !response?.url) {
    return console.error(t('missingFileLink'));
  }

  emits('fileUpdate', response);
  emits('fileLoading', false);
};

const copyToClipboard = () => {
  if (!fileName.value) { return; }
  navigator.clipboard.writeText(fileName.value);
};

defineExpose({
  clickUploadInput: handleUploadClick,
});

</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.fullwidth {
  width: 100%;
}

.file-upload {
  pointer-events: auto;
  display: flex;
  justify-content: space-between;

  &__label {
    position: relative;
    width: 100%;
    background: $input-background-color;
    border-radius: $form-border-radius;
    border: $input-border;
    color: $input-text-color-disabled;
    height: $input-height;
    line-height: $input-height;
    display: inline-block;
    cursor: pointer;
    overflow: hidden;

    &:before {
      content: '\A';
      position: absolute;
      background: $color-disabled;
      top: 0;
      left: 0;
      height: 100%;
      width: v-bind(progress);
      transition: 1s width;
    }
  }

  &.freeze {
    .file-upload__label {
      pointer-events: none;
    }
  }
  &.disabled {
    .file-upload__label {
      pointer-events: none;
      background-color: $color-disabled;
    }
  }

  &__name {
    position: absolute;
    top: 0;
    z-index: 1;
    display: block;
    align-items: center;
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding: 0 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: $input-font-size;
    vertical-align: middle;
    line-height: 28px;

    &_has-error {
      background: $input-border-color-error;
      color: $color-white;
      font-style: italic;
      font-size: 14px;
    }
  }

  input[type="file"] {
    visibility: hidden;
  }

  &__selected {
    input[type="file"] {
      display: none;
    }

    &_has-error {
      border-color: $input-border-color-error;
    }
  }
}
</style>
