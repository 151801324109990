import { RouteLocationRaw } from 'vue-router';
import { names, permissions } from '@/constants/routes';

export interface MenuItem {
  baseUrlLink?: string,
  link: RouteLocationRaw;
  title: string;
  permissions: Array<string>;
  disabled?: boolean;
}

export const mainMenu: Array<MenuItem> = [
  {
    link: { name: names.laboratories },
    title: 'menus.laboratories',
    permissions: permissions.laboratories,
  },
  {
    link: { name: names.shop },
    title: 'menus.shop',
    permissions: permissions.shop,
  },
  {
    link: '',
    baseUrlLink: '/admin/invoices',
    title: 'menus.shop',
    permissions: permissions.invoices,
  },
  {
    baseUrlLink: '/admin/users',
    link: {},
    title: 'menus.administration',
    permissions: permissions.administration.root,
  },
  {
    baseUrlLink: '/admin/price',
    link: {},
    title: 'menus.prices',
    permissions: permissions.price,
  },
  {
    link: { name: names.subscribe },
    title: 'menus.subscriptions',
    permissions: permissions.subscribe,
  },
];
