import { ElNotification } from 'element-plus';
import { DataAttr, MediaFields } from '@/types/manuals';

const findEl = (fieldName, supportedLocales) => {
  let formEl = '';
  const manualType = getManualType(fieldName);
  if (manualType) {
    const langIndex = fieldName.split('.')?.[3];
    const lang = supportedLocales[langIndex];
    formEl = document.querySelector(`#${lang}_${manualType}`);
  } else {
    formEl = document.querySelector(`#${fieldName}`);
  }
  return formEl;
};

const getManualType = (fieldName) => {
  if (fieldName.includes(`${MediaFields.Links}.`)) {
    return MediaFields.Links;
  } if (fieldName.includes(`${MediaFields.VideoInstructions}.`)) {
    return MediaFields.VideoInstructions;
  } if (fieldName.includes(`${MediaFields.UsersGuides}.`)) {
    return MediaFields.UsersGuides;
  }
  return '';
};


const switchToTab = (tabName) => {
  if (tabName) {
    const tabEl = document.querySelector(`#tab-${tabName}`);
    tabEl?.click();
  } else {
    console.error(`атрибут ${DataAttr.DataTabName} не указан`);
  }
};

const switchToLang = (lang) => {
  if (lang) {
    const tabLangEl = document.querySelector(`#lang-box-${lang}`);
    tabLangEl?.click();
  } else {
    console.error(`атрибут ${DataAttr.DataLang} не указан`);
  }
};

const scrollToEl = (formEl) => {
  setTimeout(() => {
    formEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, 100);
};

const scrollToError = ({ index, fieldName, supportedLocales }) => {
  if (index > 0 || !supportedLocales.length) { return; }
  const formEl = findEl(fieldName, supportedLocales);
  if (!formEl) {
    console.error('id элемента не задан');
    return;
  }
  switchToTab(formEl.getAttribute(DataAttr.DataTabName));
  switchToLang(formEl.getAttribute(DataAttr.DataLang));
  scrollToEl(formEl);
};

export const showValidationError = ({ fields, t, supportedLocales, isScrollToError }) => {
  const messages = [];
  if (fields) {
    Object.keys(fields).forEach((fieldName, index) => {
      if (isScrollToError) {
        scrollToError({ index, fieldName, supportedLocales });
      }
      fields[fieldName].forEach((i) => {
        if (!i.message) { return false; }
        messages.push(i.message);
      });
    });

    ElNotification({
      title: t('notifications.orgShopError'),
      message: messages.join('</br>'),
      type: 'warning',
      position: 'bottom-right',
      dangerouslyUseHTMLString: true,
    });
  }
};
