<template>
  <svg v-bind="attrs">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.7173 0.936344C24.24 1.45901 24.5009 2.08657 24.5 2.81901V21.4857C24.5 22.219 24.2391 22.847 23.7173 23.3697C23.1956 23.8923 22.5676 24.1532 21.8333 24.1523H3.16667C2.43333 24.1523 1.80578 23.8915 1.284 23.3697C0.762222 22.8479 0.500889 22.2199 0.5 21.4857V2.81901C0.5 2.08568 0.761333 1.45812 1.284 0.936344C1.80667 0.414566 2.43422 0.153233 3.16667 0.152344H21.8333C22.5667 0.152344 23.1947 0.413677 23.7173 0.936344ZM2.69821 21.9555C2.54903 21.8063 2.50039 21.6853 2.5 21.4842V2.81901C2.5 2.62092 2.54715 2.50136 2.69701 2.35176C2.84856 2.20046 2.9702 2.15273 3.16819 2.15234H21.8333C22.0315 2.15234 22.1522 2.19961 22.3031 2.35056C22.454 2.50143 22.5002 2.62087 22.5 2.81658V2.81901V21.4857C22.5 21.685 22.4523 21.806 22.3019 21.9567C22.1534 22.1054 22.0346 22.1526 21.8358 22.1523H21.8333H3.16667C2.96747 22.1523 2.84755 22.1048 2.69821 21.9555ZM9.99972 9.65206V14.6521H14.9997V9.65206H9.99972ZM13.4997 13.1521H11.4997V11.1521H13.4997V13.1521ZM9.74972 18.6521V20.4213H11.2497V18.6521H13.7497V20.4213H15.2497V18.6521H18.9997V14.9021H20.769V13.4021H18.9997V10.9021H20.769V9.40206H18.9997V5.65206H15.2497V3.88281H13.7497V5.65206H11.2497V3.88281H9.74972V5.65206H5.99972V9.40206H4.23047V10.9021H5.99972V13.4021H4.23047V14.9021H5.99972V18.6521H9.74972ZM17.4997 7.15206V17.1521H7.49972V7.15206H17.4997Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 25, height: 25 });
</script>
