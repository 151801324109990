<template>
  <svg v-bind="attrs">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.7173 4.784C28.24 5.30667 28.5009 5.93422 28.5 6.66667V25.3333C28.5 26.0667 28.2391 26.6947 27.7173 27.2173C27.1956 27.74 26.5676 28.0009 25.8333 28H7.16667C6.43333 28 5.80578 27.7391 5.284 27.2173C4.76222 26.6956 4.50089 26.0676 4.5 25.3333V6.66667C4.5 5.93333 4.76133 5.30578 5.284 4.784C5.80667 4.26222 6.43422 4.00089 7.16667 4H25.8333C26.5667 4 27.1947 4.26133 27.7173 4.784ZM6.69821 25.8031C6.54903 25.6539 6.50039 25.5329 6.5 25.3318V6.66667C6.5 6.46858 6.54715 6.34902 6.69701 6.19942C6.84856 6.04812 6.9702 6.00038 7.16819 6H25.8333C26.0315 6 26.1522 6.04727 26.3031 6.19821C26.454 6.34909 26.5002 6.46852 26.5 6.66424V6.66667V25.3333C26.5 25.5326 26.4523 25.6536 26.3019 25.8043C26.1534 25.9531 26.0346 26.0002 25.8358 26H25.8333H7.16667C6.96747 26 6.84755 25.9525 6.69821 25.8031ZM11.5833 20H18.0833C18.3903 20 18.6476 19.9042 18.8552 19.7125C19.0628 19.5208 19.1667 19.2833 19.1667 19V16.8L22.1729 19.025C22.4438 19.225 22.7326 19.2583 23.0396 19.125C23.3465 18.9917 23.5 18.7667 23.5 18.45V13.55C23.5 13.2333 23.3465 13.0083 23.0396 12.875C22.7326 12.7417 22.4438 12.775 22.1729 12.975L19.1667 15.2V13C19.1667 12.7167 19.0628 12.4792 18.8552 12.2875C18.6476 12.0958 18.3903 12 18.0833 12H11.5833C11.2764 12 11.0191 12.0958 10.8115 12.2875C10.6038 12.4792 10.5 12.7167 10.5 13V19C10.5 19.2833 10.6038 19.5208 10.8115 19.7125C11.0191 19.9042 11.2764 20 11.5833 20Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 33, height: 32 });
</script>
