<template>
  <svg v-bind="attrs">
    <path
      d="M2.873 7.25L15 7.25L15 8.75L2.873 8.75L8.56925 14.4462L7.5 15.5L6.55671e-07 8L7.5 0.499999L8.56925 1.55375L2.873 7.25Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 15, height: 16 });
</script>
