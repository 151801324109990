<template>
  <svg v-bind="attrs">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.7173 4.93634C28.24 5.45901 28.5009 6.08657 28.5 6.81901V25.4857C28.5 26.219 28.2391 26.847 27.7173 27.3697C27.1956 27.8923 26.5676 28.1532 25.8333 28.1523H7.16667C6.43333 28.1523 5.80578 27.8915 5.284 27.3697C4.76222 26.8479 4.50089 26.2199 4.5 25.4857V6.81901C4.5 6.08568 4.76133 5.45812 5.284 4.93634C5.80667 4.41457 6.43422 4.15323 7.16667 4.15234H25.8333C26.5667 4.15234 27.1947 4.41368 27.7173 4.93634ZM6.69821 25.9555C6.54903 25.8063 6.50039 25.6853 6.5 25.4842V6.81901C6.5 6.62092 6.54715 6.50136 6.69701 6.35176C6.84856 6.20046 6.9702 6.15273 7.16819 6.15234H16.5V15.4857L19.8333 13.4857L23.1667 15.4857V6.15234H25.8333C26.0315 6.15234 26.1522 6.19961 26.3031 6.35056C26.454 6.50143 26.5002 6.62087 26.5 6.81658V6.81901V25.4857C26.5 25.685 26.4523 25.806 26.3019 25.9567C26.1534 26.1054 26.0346 26.1526 25.8358 26.1523H25.8333H7.16667C6.96747 26.1523 6.84755 26.1048 6.69821 25.9555Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 33, height: 33 });
</script>
