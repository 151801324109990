<template>
  <svg v-bind="attrs">
    <path
      d="M12.2481 29.155L9.8688 25.145L5.3508 24.155L5.7918 19.504L2.73047 16.0013L5.7918 12.4987L5.3508 7.84766L9.8688 6.85766L12.2481 2.84766L16.4995 4.65265L20.7508 2.84766L23.1301 6.85766L27.6481 7.84766L27.2071 12.4987L30.2685 16.0013L27.2071 19.504L27.6481 24.155L23.1301 25.145L20.7508 29.155L16.4995 27.35L12.2481 29.155ZM13.0995 26.6013L16.4995 25.1603L19.9405 26.6013L21.8328 23.4013L25.4995 22.5603L25.1661 18.8013L27.6328 16.0013L25.1661 13.1603L25.4995 9.40132L21.8328 8.60132L19.8995 5.40132L16.4995 6.84232L13.0585 5.40132L11.1661 8.60132L7.49947 9.40132L7.8328 13.1603L5.36614 16.0013L7.8328 18.8013L7.49947 22.6013L11.1661 23.4013L13.0995 26.6013ZM16.4995 22.309C16.8004 22.309 17.055 22.2048 17.2635 21.9963C17.4721 21.7877 17.5765 21.5329 17.5765 21.232C17.5765 20.9311 17.4721 20.6764 17.2635 20.468C17.055 20.2595 16.8004 20.1553 16.4995 20.1553C16.1986 20.1553 15.9439 20.2595 15.7355 20.468C15.5268 20.6764 15.4225 20.9311 15.4225 21.232C15.4225 21.5329 15.5268 21.7877 15.7355 21.9963C15.9439 22.2048 16.1986 22.309 16.4995 22.309ZM15.4995 17.4373H17.4995V9.43732H15.4995V17.4373Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 33, height: 32 });
</script>
