import dayjs from 'dayjs';

import ru from 'dayjs/locale/ru';
import fr from 'dayjs/locale/fr';
import hy from 'dayjs/locale/hy-am';
import localeData from 'dayjs/plugin/localeData';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale(ru);
dayjs.locale(fr);
dayjs.locale(hy);
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);

export default dayjs;
